import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  mixins: [sharedActions],
  computed: {
    tab() {
      if (this.$route.params) {
        return this.$route.params.tab;
      }
      return null;
    },
  },
};
