import dialogTypes from "@/components/Dialogs/dialogTypes";
import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";
import { mapActions, mapGetters } from "vuex";
import planActions from "@/calendesk/mixins/planActions";
import roleActions from "@/calendesk/mixins/roleActions";
import {
  getSubscriptionName,
  pushEvent,
} from "@/lib/calendesk-js-library/tools/helpers";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import sharedListActions from "@/calendesk/mixins/sharedListActions";
import confirmDialogTypes from "@/components/ConfirmDialogs/confirmDialogTypes";

export default {
  mixins: [planActions, roleActions, sharedListActions],
  computed: {
    ...mapGetters({
      getRefreshUserSubscriptionsListStatus:
        "subscriptions/getRefreshUserSubscriptionsListStatus",
    }),
  },
  methods: {
    ...mapActions({
      searchSubscriptions: "subscriptions/searchSubscriptions",
      fetchUserSubscriptions: "subscriptions/fetchAllUserSubscriptions",
      deleteUserSubscription: "subscriptions/deleteUserSubscription",
      cancelUserSubscription: "subscriptions/cancelUserSubscription",
      refreshUserSubscriptionsList:
        "subscriptions/refreshUserSubscriptionsList",
    }),
    getSubscriptionName,
    openPaymentsDialog(item) {
      this.openDialog({
        type: dialogTypes.USER_PAYMENT_TRANSACTIONS,
        size: this.getLargeDialogWidth,
        title: this.$trans("payment_history"),
        data: {
          user: item.user,
          paymentMode: 2,
          userSubscription: item,
        },
      });
    },
    openAddUserSubscription() {
      if (this.canUseSubscriptions) {
        this.openDialog({
          type: dialogTypes.CREATE_USER_SUBSCRIPTION,
          size: dialogSize.LARGE,
          ctaButtonAction: "handleSave",
          ctaButtonIcon: "$plus-circle",
          ctaButtonTitle: this.$trans("add"),
          title: this.$trans("create_user_subscription_button_title"),
          persistent: true,
        });
      } else {
        this.$root.$emit("openFeatureNotAvailableDialog");
      }
    },
    sendCustomerNotification(selectedSubscriptions) {
      const selectedUsers = selectedSubscriptions.map(
        (userSubscription) => userSubscription.user
      );

      this.sendNotificationsToUsers(
        selectedUsers,
        this.$helpers.customerNotificationCustomerType.user,
        this.$trans("send_customers_notification_title")
      );
    },
    handleDeleteUserSubscriptions(userSubscriptions) {
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        confirmAction: () => {
          this.setCommonDialogLoader(true);
          pushEvent("removeUserSubscription");
          let numFailedRequests = 0;

          const deletePromises = userSubscriptions.map((userSubscription) => {
            return this.deleteUserSubscription(userSubscription.id).catch(
              () => {
                numFailedRequests++;
              }
            );
          });

          Promise.all(deletePromises).finally(() => {
            if (numFailedRequests === deletePromises.length) {
              // All requests failed
              errorNotification("delete_failed", null, false);
            } else {
              successNotification("delete_succeeded");
            }

            this.refreshUserSubscriptionsList();
            this.setCommonDialogLoader(false);
            this.closeConfirmDialog();
          });
        },
      });
    },
    handleCancelUserSubscriptions(userSubscriptions) {
      this.openConfirmDialog({
        type: confirmDialogTypes.CANCEL_USER_SUBSCRIPTION,
        data: {
          userSubscriptions: userSubscriptions,
          cancelNow: false,
        },
        close: function () {},
      });
    },
    canCancelUserSubscription(userSubscription) {
      return userSubscription.status === "active";
    },
    canDeleteUserSubscription(userSubscription) {
      return (
        userSubscription.status === "initiated" ||
        userSubscription.status === "canceled"
      );
    },
  },
};
