import dialogTypes from "@/components/Dialogs/dialogTypes";
import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";
import planActions from "@/calendesk/mixins/planActions";
import { mapActions, mapGetters } from "vuex";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";
import {
  errorResponseIsTypeOfCode,
  pushEvent,
} from "@/lib/calendesk-js-library/tools/helpers";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import roleActions from "@/calendesk/mixins/roleActions";

export default {
  mixins: [planActions, roleActions],
  computed: {
    ...mapGetters({
      getRefreshSimpleStoreProductListStatus:
        "simpleStore/getRefreshSimpleStoreProductListStatus",
      getRefreshSimpleStoreTransactionListStatus:
        "simpleStore/getRefreshSimpleStoreTransactionListStatus",
    }),
  },
  methods: {
    ...mapActions({
      fetchSimpleStoreProductList: "simpleStore/fetchAllProducts",
      fetchSimpleStoreTransactionList:
        "simpleStore/fetchAllProductTransactions",
      deleteSimpleStoreProduct: "simpleStore/delete",
      refreshProductList: "simpleStore/refreshProductList",
      refreshSimpleStoreTransactionList: "simpleStore/refreshTransactionList",
      deleteSimpleStoreProductPaymentTransaction:
        "simpleStore/deletePaymentTransaction",
    }),
    handleAddProduct() {
      if (this.canUseSimpleStoreProduct) {
        this.$root.$emit("openManageSimpleStoreProductModal", {
          title: this.$trans("simple_store_product_add_title"),
          ctaButtonIcon: "$plus-circle",
          ctaButtonTitle: this.$trans("add"),
        });
      } else {
        this.$root.$emit("openFeatureNotAvailableDialog");
      }
    },
    handleUpdateProduct(item) {
      if (this.canUseSimpleStoreProduct) {
        this.$root.$emit("openManageSimpleStoreProductModal", {
          title: this.$trans("simple_store_product_update_title"),
          ctaButtonIcon: "$save",
          ctaButtonTitle: this.$trans("save"),
          product: item,
        });
      } else {
        this.$root.$emit("openFeatureNotAvailableDialog");
      }
    },
    handleDeleteProduct(data) {
      if (this.canUseSimpleStoreProduct) {
        this.openConfirmDialog({
          type: sharedConfirmDialogTypes.COMMON,
          confirmAction: () => {
            pushEvent("removeSimpleStoreProduct");

            this.setCommonDialogLoader(true);
            const requests = [];

            if (Array.isArray(data)) {
              data.forEach((product) => {
                requests.push(this.deleteSimpleStoreProduct(product.id));
              });
            } else {
              requests.push(this.deleteSimpleStoreProduct(data.id));
            }

            Promise.all(requests)
              .catch((error) => {
                if (
                  errorResponseIsTypeOfCode(error, "PRODUCT_HAS_TRANSACTIONS")
                ) {
                  errorNotification(
                    "product_has_transactions_delete_error",
                    error,
                    false,
                  );
                } else {
                  errorNotification(null, error);
                }
              })
              .finally(() => {
                this.refreshProductList();
                this.setCommonDialogLoader(false);
                this.closeConfirmDialog();
              });
          },
        });
      } else {
        this.$root.$emit("openFeatureNotAvailableDialog");
      }
    },
    handleAddProductTransactions() {
      if (this.canUseSimpleStoreProduct) {
        this.openDialog({
          type: dialogTypes.ADD_SIMPLE_STORE_PRODUCT_PAYMENT_TRANSACTION,
          title: this.$trans("add_transaction"),
          size: dialogSize.MIDDLE,
          ctaButtonAction: "handleSave",
          ctaButtonIcon: "$plus-circle",
          ctaButtonTitle: this.$trans("add"),
        });
      } else {
        this.$root.$emit("openFeatureNotAvailableDialog");
      }
    },
    handleDeleteProductTransactions(transactions) {
      if (this.canUseSimpleStoreProduct) {
        this.openConfirmDialog({
          type: sharedConfirmDialogTypes.COMMON,
          confirmAction: () => {
            pushEvent("removeSimpleStoreProductPaymentTransaction");

            this.setCommonDialogLoader(true);
            const requests = [];

            transactions.forEach((transaction) => {
              requests.push(
                this.deleteSimpleStoreProductPaymentTransaction(transaction.id),
              );
            });

            Promise.all(requests)
              .catch((error) => {
                errorNotification(null, error);
              })
              .finally(() => {
                this.refreshSimpleStoreTransactionList();
                this.setCommonDialogLoader(false);
                this.closeConfirmDialog();
              });
          },
        });
      } else {
        this.$root.$emit("openFeatureNotAvailableDialog");
      }
    },
  },
};
