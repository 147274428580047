<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col>
        <v-row no-gutters align="center">
          <v-col class="pb-1" cols="12">
            <span class="text-caption light--text">
              {{ subtitle | truncate(titleTruncate) }}
            </span>
          </v-col>
          <v-col cols="12" align-self="center">
            <v-row no-gutters align="center">
              <v-col cols="12" class="mb-2">
                <span class="text-h4 mr-2">
                  {{ title | truncate(titleTruncate) }}
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "NewAppBarRoute",
  props: {
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
  },
  computed: {
    titleTruncate() {
      return this.$vuetify.breakpoint.width > 960 ? 70 : 35;
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 768px) {
  .row-class {
    width: 350px;
  }
}
</style>
