<template>
  <div v-show="getStatsLoaded" class="full-width">
    <app-bar-bookings v-if="tab === availableTabs.sales.events" />
    <app-bar-user-subscriptions
      v-if="tab === availableTabs.sales.subscriptions"
    />
    <app-bar-product-transactions v-if="tab === availableTabs.sales.products" />
  </div>
</template>

<script>
import AppBarBookings from "@/views/dashboard/pages/Sales/AppBar/AppBarBookings.vue";
import sharedAppBarActions from "@/calendesk/mixins/sharedAppBarActions";
import sharedTabsActions from "@/calendesk/mixins/sharedTabsActions";
import AppBarProductTransactions from "@/views/dashboard/pages/Sales/AppBar/AppBarProductTransactions.vue";
import AppBarUserSubscriptions from "@/views/dashboard/pages/Sales/AppBar/AppBarUserSubscriptions.vue";

export default {
  components: {
    AppBarProductTransactions,
    AppBarUserSubscriptions,
    AppBarBookings,
  },
  mixins: [sharedAppBarActions, sharedTabsActions],
};
</script>
